import '../../node_modules/bootstrap/js/dist/dropdown';
import gsap from 'gsap';
import Headroom from "headroom.js";
import scrollTrigger from "gsap/ScrollTrigger";
import EmblaCarousel from 'embla-carousel'
gsap.registerPlugin(scrollTrigger);

gsap.defaults({duration: .8, ease: 'power2.out'});

const getParent = (element, selector = null) => {
    if (selector !== null) {
        do {
        if (element.matches(selector)) return element;
        element = element.parentElement || element.parentNode;
        } while (element !== null && element.nodeType === 1);

    } else {
        return element.parentNode
    }

    return null;
};
const getAnimation = name => {
    const elements = document.querySelectorAll(`[data-animation-target="${name}"]`);

    return {
        elements: Array.from(elements),
        section: elements.length > 0 ? getParent(elements[0], '[data-animation-section]') : null
    }
};
const stickyHeaderClasses = {
    initial : 'header',
    pinned : 'header-pinned',
    unpinned : 'header-unpinned',
    top : 'header-top',
    notTop : 'header-not-top',
    bottom : 'header-bottom',
    notBottom : 'header-not-bottom',
    frozen: 'header-frozen'
};
const animationShift = 100;

document.addEventListener("DOMContentLoaded", () => {
    const headerEL = document.getElementById('js-header');

    if (headerEL) {
        new Headroom(headerEL, {
            classes: stickyHeaderClasses
        }).init();
    };
    // STICKY HEADER END

    gsap.fromTo(getAnimation('header').elements, {
        x: animationShift,
        autoAlpha: 0
    }, {
        x: 0,
        autoAlpha: 1
    });

    [
        getAnimation('intro'),
        getAnimation('footer'),
        getAnimation('description'),
        getAnimation('clubs'),
        getAnimation('software'),
        getAnimation('partners'),
        getAnimation('advantages'),
        getAnimation('roadmap'),
        getAnimation('feedback'),
    ].forEach(({elements, section}) => {
        gsap.timeline({
            scrollTrigger: {
                trigger: section,
                start: '20% 80%',
                markers: false
            }
        })
            .fromTo(elements, {
                x: animationShift,
                autoAlpha: 0
            }, {
                x: 0,
                autoAlpha: 1,
                stagger: .15,
                delay: .1,
                onComplete: () => {
                    gsap.set(elements, {clearProps: 'x,y'});
                    section.classList.add('is-animated')
                }
            });
    });
    // ANIMATION END

    const partnersCarouselEl = document.querySelector('#js-partners-carousel');
    const partnersPrevBtn = document.querySelector('[data-partners-prev]');
    const partnersNextBtn = document.querySelector('[data-partners-next]');
    const partnersCarousel = EmblaCarousel(partnersCarouselEl, {
        loop: true,
        active: false,
        align: 'center',
        slidesToScroll: 'auto',
        breakpoints: {
            '(min-width: 1024px)': {
                active: false
            },
            '(max-width: 1023px)': {
                active: true
            }
        }
    });
    partnersPrevBtn.addEventListener('click', partnersCarousel.scrollPrev, false);
    partnersNextBtn.addEventListener('click', partnersCarousel.scrollNext, false);
    // PARTNERS MOBILE CAROUSEL END
});